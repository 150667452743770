/**
* Generated automatically at built-time (2024-12-10T09:51:04.358Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekjokken-samlinger-myoutdoorkitchen",templateKey: "sites/100-db5e3141-4c55-4730-b726-4837f445e0a4"};