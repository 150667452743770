/**
* Generated automatically at built-time (2024-12-10T09:51:04.617Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekjokken-black-friday",templateKey: "sites/100-dece2986-2bc5-4e81-a1d0-284f6502de8d"};